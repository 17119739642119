<template>
  <div
    class="category-tile"
    :class="[
      'category-tile--' + type + brandClass,
      {
        'category-tile--ellipsis': useEllipsis
      }
    ]"
  >
    <nuxt-link
      class="category-tile__link cardTile"
      :to="getLink(category.TechnicalName)"
    >
      <div class="category-tile__link__left">
        <div
          class="category-tile__link__left__inchIcon"
          v-if="category.IsInchCategory && showInchIcon"
          :title="$tv('inchIconText', 'categories')"
        >
          <Ruler />
        </div>
        <div class="category-tile__link__left__image">
          <img
            v-if="lazy"
            :src="imageUrl(category)"
            :alt="'Design sketch '+$tv(category.TechnicalName, 'categories')"
            v-lazy-load
          >
          <img
            v-else
            :src="imageUrl(category)"
            :alt="'Design sketch '+$tv(category.TechnicalName, 'categories')"
          >
        </div>
        <div
          class="category-tile__link__left__name"
        >
          {{ $tv(category.TechnicalName, 'categories') }}
        </div>
      </div>
      <div class="category-tile__link__right">
        <div
          class="category-tile__link__right__description"
        >
          <span class="ellipsis-text">{{ category.ShortDescription }}</span>
        </div>
        <div class="category-tile__link__right__arrow">
          <NavigationChevronRight />
        </div>
      </div>
    </nuxt-link>
  </div>
</template>
<script>
import itemImageUrlMixin from '~/mixins/item_image_url'
import {getUrlFromTechnicalName} from '~/mixins/category_urls'
import {REGION_KEYS} from '~/assets/js/constants.js'
import NavigationChevronRight from '~/assets/icons/navigation_chevron_right.svg?component'
import Ruler from '~/assets/icons/ruler.svg?component'

export default {
  components: {NavigationChevronRight, Ruler},
  mixins: [itemImageUrlMixin],
  props: {
    category: {
      type: Object,
      default () {
        return {}
      }
    },
    type: {
      type: String,
      default () {
        return 'small'
      }
    },
    path: {
      type: String,
      default () {
        return ''
      }
    },
    lazy: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      image: null
    }
  },
  computed: {
    brandClass() {
      if (this.category?.brands) {
        let brandCodes = this.category?.brands.map(b => b.Code.toLowerCase()).sort()
        return ` brand-${brandCodes.join('')}`
      }
      return null
    },
    showInchIcon() {
      return this.$globalization.__currentRegion === REGION_KEYS.NA
    },
    useEllipsis() {
      const nameParts = this.$tv(this.category?.TechnicalName, 'categories').split(' ')
      return nameParts.length === 1 && nameParts[0].length > 14
    }
  },
  methods: {
    getLink(name) {
      return this.localePath(this.path + '/' + getUrlFromTechnicalName(name, this.$i18n.locale), this.$i18n.locale)
    }
  }
}
</script>

<style lang="scss">
.category-tile {
  .category-tile__link {
    display: flex;
    flex-direction: row;
    background-color: $color__content--background__primary;
    color: $color__text__default;
    width: 24.5rem;

    &__left {
      display: flex;
      flex-direction: column-reverse;
      position: relative;

      &__inchIcon svg {
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 0;
        padding: .25rem;
        transform: rotateZ(75deg);
        fill: $color--btn__secondary;
      }

      &__image {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        pointer-events: none;
        height: 7.875rem;

        @include breakpoint-down($md) {
          height: 5.563rem;
        }

        img {
          max-height: calc(100% - .75rem);
        }
      }

      &__name {
        display: flex;
        align-items: center;
        padding: 0 .375rem;
        border-top: .063rem solid $color--category-tile__border;
        min-height: 1.5rem;
        font-size: $base-text-font-size;
      }
    }
  }

  &--small {
    .category-tile__link {
      flex-direction: column;
      width: 9.75rem;

      @include breakpoint-down($md) {
        width: 6.75rem;
      }

      .category-tile__link__left {
        flex-direction: column;
      }

      .category-tile__link__right {
        display: none;
      }
    }
  }

  &--medium {
    @include breakpoint-down($md) {
      flex-basis: 100%;
    }

    .category-tile__link {
      color: $color__text__default;
      box-shadow: 0 .188rem .375rem $color--card__shadow;

      .category-tile__link__left {
        width: 50%;
        color: $color__primary;
        font-weight: $base-text-font-weight-bold;

        &__name {
          border-top: 0;

          @include breakpoint-up($md) {
            padding-left: $default-margin-padding-width-small;
            padding-top: .375rem;
          }
        }

        &__image {
          @include breakpoint-up($md) {
            height: 7.5rem;
          }
        }
      }

      .category-tile__link__right {
        width: 50%;
        margin: .75rem 1.125rem .5rem .75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__description {
          height: 6.438rem;
          overflow: hidden;
          font-weight: $base-text-font-weight-light;
          font-size: $base-text-font-size;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;

          .ellipsis-text {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        &__arrow {
          display: flex;
          justify-content: flex-end;
          font-size: 1.5rem;
          width: 100%;
          color: $color__default;

          .icon--svg {
            position: relative;
            right: -.75rem;
          }
        }
      }

      @include breakpoint-down($md) {
        width: 100%;
        height: 3.125rem;

        .category-tile__link__left {
          flex-direction: column;
          justify-content: center;
          width: calc(100% - 1.875rem);

          &__name {
            font-size: $base-text-font-size;
            line-height: $base-text-line-height;
          }

          &__image {
            display: none;
          }
        }

        .category-tile__link__right {
          width: 1.25rem;
          margin: 0 $default-margin-padding-width-small 0 0;
          justify-content: center;

          &__description {
            display: none;
          }
        }
      }
    }
  }

  @include breakpoint-down($sm) {
    width: calc(33% - (#{$default-margin-padding-width-small}));

    & > a.category-tile__link.cardTile {
      display: flex;
      flex-grow: 1;
      width: auto;
    }
  }

  @include breakpoint-down($xs) {
    width: calc(50% - (#{$default-margin-padding-width-small}));
  }

  &--ellipsis {
    .category-tile__link__left__name {
      display: inline;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.5rem;
    }
  }
}
</style>
